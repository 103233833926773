import Header from "./components/App/Header";
import { useState, useEffect } from "react";
import AppContext from "./components/Context/Context";
import { GlobalStateProvider } from "./components/Context/GlobalStateContext";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Pages/Home";
import Login from "./components/Pages/Login";
import RequireAuth from "./components/Auth/RequireAuth";
import NotRequireAuth from "./components/Auth/NotRequireAuth";
import Signup from "./components/Pages/Signup";
import ForgotPassword from "./components/Pages/ForgotPassword";
import JobReview from "./components/Pages/JobReview";
import JobReviewMonthlyStats from "./components/Pages/JobReviewMonthlyStats";
import EmployeeRates from "./components/Pages/EmployeeRates";
import EmployeeBonusAndDeductions from "./components/Pages/EmployeeBonusAndDeductions";
import EmployeeView from "./components/Pages/EmployeeView";
import EmployeeSummary from "./components/Pages/EmployeeSummary";
import MNVMoverShiftData from "./components/Pages/MNVMoverShiftData";
import LogInUserName from "./components/App/LogInUserName";
import NotAccess from "./components/Pages/NotAccess";

function App() {
  const [logInUserName, setLogInUserName] = useState<string | undefined>("");

  const handleUserNameChange = (userName: string | undefined) => {
    setLogInUserName(userName);
  };

  return (
    <main>
      {/* <NotificationContainer /> */}
      <GlobalStateProvider>
        <div className="App--main">
          <Header />
          <LogInUserName onUserNameChange={handleUserNameChange} />
          <div
            className={logInUserName == "" ? "Body--Main p-all0" : "Body--Main"}
          >
            <Routes>
              <Route
                path=""
                element={
                  <RequireAuth>
                    <Home />
                  </RequireAuth>
                }
              />
              <Route
                path="home"
                element={
                  <RequireAuth>
                    <Home />
                  </RequireAuth>
                }
              />
              <Route
                path="login"
                element={
                  <NotRequireAuth>
                    <Login />
                  </NotRequireAuth>
                }
              />
              <Route
                path="signup"
                element={
                  <NotRequireAuth>
                    <Signup />
                  </NotRequireAuth>
                }
              />
              <Route
                path="forgotpassword"
                element={
                  <NotRequireAuth>
                    <ForgotPassword />
                  </NotRequireAuth>
                }
              />
              <Route
                path="jobreview"
                element={
                  <RequireAuth>
                    <JobReview />
                  </RequireAuth>
                }
              />
              <Route
                path="jobreviewmonthlystats"
                element={
                  <RequireAuth>
                    <JobReviewMonthlyStats />
                  </RequireAuth>
                }
              />
              <Route
                path="employeerates"
                element={
                  <RequireAuth>
                    <EmployeeRates />
                  </RequireAuth>
                }
              />
              <Route
                path="employeebonusanddeductions"
                element={
                  <RequireAuth>
                    <EmployeeBonusAndDeductions />
                  </RequireAuth>
                }
              />
              <Route
                path="employeeview"
                element={
                  <RequireAuth>
                    <EmployeeView />
                  </RequireAuth>
                }
              />
              <Route
                path="employeesummary"
                element={
                  <RequireAuth>
                    <EmployeeSummary />
                  </RequireAuth>
                }
              />
              <Route
                path="employeetimelog"
                element={
                  <RequireAuth>
                    <MNVMoverShiftData />
                  </RequireAuth>
                }
              />
              <Route
                path="notaccess"
                element={
                    <NotAccess />
                }
              />
              <Route
                path="*"
                element={
                  <NotRequireAuth>
                    <Login />
                  </NotRequireAuth>
                }
              />
            </Routes>
          </div>
        </div>
      </GlobalStateProvider>
    </main>
  );
}

export default App;
