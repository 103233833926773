import React from "react";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
function NotAccess() {
  const globalState = useGlobalState();
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {}, []);

  const LogOutUser = () => {
    AppContext.SetCookie("Token", "", 0);
    AppContext.SetCookie("RefreshToken", "", 0);
    globalState.setLogInUserName("");
    globalState.setLogInUserImage("");
    globalState.setLogInUserRole("");
    AppContext.LogInUserName = "";
    window.location.href = "/login";
  };

  return (
    <div>
      <div className="inline-block-d width-100 text-center page-404">
        {/* <h1>404</h1> */}
        <span>You do not have access.</span>
        <div className="flex-dj">
          <a className="flex-daj btn-secondary" onClick={() => LogOutUser()}>
            LogOut
          </a>
        </div>
      </div>
    </div>
  );
}

export default NotAccess;
