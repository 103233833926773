import React, { useState, useEffect, useRef } from "react";
type Props = {
  listData: any;
  selectAllName: any;
  selectFewName: any;
  selectedPID: any;
  updateSelectedPIDForParent: Function;
  type: any;
};

export default function MultiSelectionDropdown(props: Props) {
  const [showOptions, setShowOptions] = useState(false);
  const [selectAllName, setSelectAllName] = useState(props.selectAllName);
  const [selectFewName, setSelectFewName] = useState(props.selectFewName);
  const [selectedPID, setSelectedPID] = useState(props.selectedPID);
  const [selectedName, setSelectedName] = useState(props.selectAllName);
  const componentRef = useRef<any>(null);
  const [currentList, setCurrentList] = useState<any>([]);
  const currentListRef = useRef<any | null>(null);
  const [selectUnSelectAll, setSelectUnSelectAll] = useState<any>(true);
  const [searchedValue, setSearchedValue] = useState("");

  useEffect(() => {
    setSearchedValue("");
    setSelectAllName(props.selectAllName);
    setSelectFewName(props.selectFewName);
  }, []);

  useEffect(() => {
    setSelectedPID(props.selectedPID);
  }, [props.selectedPID]);

  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (props.listData && props.listData.length > 0) {
      const deepCopy1 = JSON.parse(JSON.stringify(props.listData));
      for (let index = 0; index < deepCopy1.length; index++) {
        if (selectedPID != null && selectedPID != 0) {
          if (selectedPID.includes(",")) {
            var thisPID = selectedPID.split(",");
            for (let i = 0; i < thisPID.length; i++) {
              if (thisPID[i] == deepCopy1[index].PID) {
                deepCopy1[index].IsSelected = true;
                break;
              } else {
                deepCopy1[index].IsSelected = false;
              }
            }
          } else {
            if (selectedPID == deepCopy1[index].PID) {
              deepCopy1[index].IsSelected = true;
            } else {
              deepCopy1[index].IsSelected = false;
            }
          }
        } else {
          deepCopy1[index].IsSelected = false; //deepCopy1[index].IsSelected = true;  : ZI
        }
      }
      setCurrentList(deepCopy1);
      currentListRef.current = deepCopy1;
    }
  }, [props.listData, selectedPID]);

  useEffect(() => {
    if (currentList && currentList.length > 0) {
      checkSelectUnselectAll();
    }
  }, [currentList]);

  function updateListdata(index: number) {
    let tempArray = [...currentList];
    tempArray[index].IsSelected = !tempArray[index].IsSelected;
    setCurrentList(tempArray);
  }

  function checkIfSingleOrMultipleSelected(allChecked: boolean) {
    let count = 0;
    let selectedIndex = 0;
    for (let i = 0; i < currentList.length; i++) {
      if (currentList[i].IsSelected) {
        count++;
        selectedIndex = i;
      }
    }
    if (allChecked == true) {
      setSelectedName(selectAllName);
    }
    if (allChecked == false) {
      setSelectedName(selectFewName);
    }

    if (count == 1) {
      setSelectedName(currentList[selectedIndex].Name);
    }

    sendResponseToParent();
  }

  function sendResponseToParent() {
    let selectedIds = "";
    for (let i = 0; i < currentList.length; i++) {
      if (currentList[i].IsSelected) {
        selectedIds += currentList[i].PID + ",";
      }
    }
    if (selectedIds != "") {
      selectedIds = selectedIds.replace(/,$/, "");
    }
    props.updateSelectedPIDForParent(props.type, selectedIds);
  }

  function checkSelectUnselectAll() {
    let allChecked = true;
    for (let i = 0; i < currentList.length; i++) {
      if (currentList[i].IsSelected === false) {
        allChecked = false;
        break;
      }
    }
    setSelectUnSelectAll(allChecked);

    checkIfSingleOrMultipleSelected(allChecked);
  }

  function selectUnselectAll(event: any) {
    let l_currentList = [...currentList];
    if (event.target.checked) {
      //select all
      setSelectUnSelectAll(true);
      for (let i = 0; i < l_currentList.length; i++) {
        l_currentList[i].IsSelected = true;
      }

      let selectedName = selectAllName;
      setSelectedName(selectedName);
    } else {
      //unselect all
      setSelectUnSelectAll(false);
      for (let i = 0; i < l_currentList.length; i++) {
        l_currentList[i].IsSelected = false;
      }
      let selectedName = selectFewName;
      setSelectedName(selectedName);
    }

    setCurrentList(l_currentList);
  }
  function displayList() {
    if (props.type != 2) {
      setShowOptions(true);
    } else if (props.type == 2) {
      if (searchedValue.length >= 2) {
        setShowOptions(true);
      } else {
        setShowOptions(false);
      }
    }
  }

  useEffect(() => {
    if (
      searchedValue !== "" &&
      currentListRef.current &&
      currentListRef.current.length > 0
    ) {
      displayList();
      filterByKeyUp();
    } else {
      setCurrentList(currentListRef.current);
    }
  }, [searchedValue]);

  function filterByKeyUp() {
    let filterArray: any = [];
    filterArray = filterByValue(currentListRef.current, searchedValue);
    setCurrentList(filterArray);
  }

  function filterByValue(array: any, value: string) {
    return array.filter(
      (data: any) =>
        data.Name && data.Name.toLowerCase().includes(value.toLowerCase())
    );
  }

  return (
    <>
      <div className="multi-select p-relative" ref={componentRef}>
        <a className="multi-ps flex-da flex-jsb p-relative">
          <input
            value={searchedValue}
            onChange={(e: any) => setSearchedValue(e.target.value)}
            placeholder={selectedName}
            onClick={() => displayList()}
          />
          <img
            src="/images/icon-search.svg"
            width="15"
            height="15"
            alt="search"
          />
        </a>
        <div className={showOptions ? "multi-drop" : "multi-drop no-display"}>
          {currentList &&
            currentList.map((item: any, index: any) => (
              <React.Fragment key={index}>
                <div className="multi-item flex-d flex-ac flex-gap5">
                  <input
                    type="checkbox"
                    checked={item.IsSelected}
                    value={item.IsSelected}
                    onChange={(e: any) => updateListdata(index)}
                  />
                  <span>{item.Name}</span>
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    </>
  );
}
