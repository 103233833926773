import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import axiosAuth from "../Axios/Interceptors";
import moment from "moment";
import { DownloadClientsDataInExcel } from "../Shared/Utility";
function JobReviewMonthlyStats() {
  const [isAnimation, setIsAnimation] = useState(false);

  const [jobReviewMonthlyStatsData, setJobReviewMonthlyStatsData] =
    useState<any>([]);

  const [jobReviewObject, setJobReviewObject] = useState({
    NumericYear: new Date().getFullYear(),
  });

  useEffect(() => {
    MNVJobReviewGetMonthlyStatsData();
  }, [jobReviewObject]);

  function MNVJobReviewGetMonthlyStatsData() {
    setIsAnimation(true);
    let object = {} as any;
    object.numericyear = jobReviewObject.NumericYear;
    axiosAuth
      .post(
        AppContext.APIURL + "api/MNVJobReview/MNVJobReviewGetMonthlyStatsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          setJobReviewMonthlyStatsData(l_oResponse[0]);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  return (
    <>
      {isAnimation && <LoadingAnimation />}

      <div className="inline-block-d width-100 p-all10">
        <>
          <div className="flex-da justify-btw mb-10">
            <div className="field-input">
              <select
                className="input-field"
                value={jobReviewObject.NumericYear}
                onChange={(e) =>
                  setJobReviewObject((prevState: any) => ({
                    ...prevState,
                    NumericYear: e.target.value,
                  }))
                }
              >
                <option value={0}>Select Year</option>
                {AppContext.yearsList &&
                  AppContext.yearsList.map((item: any, index: any) => (
                    <React.Fragment key={index}>
                      <option value={item.ID}>{item.Name}</option>
                    </React.Fragment>
                  ))}
              </select>
            </div>
            <a
              className="btn-primary flex-daj btn-grid"
              title="Export"
              onClick={() =>
                DownloadClientsDataInExcel(
                  "tbljobreviewstats",
                  "Job Review Monthly Stats" +
                    " - " +
                    jobReviewObject.NumericYear
                )
              }
            >
              <img src="images/export.svg" width="18" height="18" />
            </a>
          </div>

          <div className="flow-auto-sm stick-first-col">
            <table className="reuse-table row-last-bold" id="tbljobreviewstats">
              <thead>
                <tr>
                  <th rowSpan={2} className="sticky-header stick-col">
                    Mover Name
                  </th>
                  <th colSpan={2} className="sticky-header">
                    January
                  </th>
                  <th colSpan={2} className="sticky-header">
                    February
                  </th>
                  <th colSpan={2} className="sticky-header">
                    March
                  </th>
                  <th colSpan={2} className="sticky-header">
                    April
                  </th>
                  <th colSpan={2} className="sticky-header">
                    May
                  </th>
                  <th colSpan={2} className="sticky-header">
                    June
                  </th>
                  <th colSpan={2} className="sticky-header">
                    July
                  </th>
                  <th colSpan={2} className="sticky-header">
                    August
                  </th>
                  <th colSpan={2} className="sticky-header">
                    September
                  </th>
                  <th colSpan={2} className="sticky-header">
                    October
                  </th>
                  <th colSpan={2} className="sticky-header">
                    November
                  </th>
                  <th colSpan={2} className="sticky-header">
                    December
                  </th>
                  <th colSpan={2} className="sticky-header">
                    Total
                  </th>
                </tr>
                <tr>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                  <th title="Reviews" className="sticky-header">
                    Rev.
                  </th>
                  <th title="Amount" className="sticky-header">
                    Amt.
                  </th>
                </tr>
              </thead>
              <tbody>
                {jobReviewMonthlyStatsData &&
                  jobReviewMonthlyStatsData.map((Item: any, index: any) => (
                    <React.Fragment key={index}>
                      <tr key={index}>
                        <td className="stick-col">{Item.Name}</td>
                        <td className="text-right">{Item.JanCount}</td>
                        <td className="text-right">
                          £
                          {Item.JanAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.FebCount}</td>
                        <td className="text-right">
                          £
                          {Item.FebAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.MarCount}</td>
                        <td className="text-right">
                          £
                          {Item.MarAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.AprCount}</td>
                        <td className="text-right">
                          £
                          {Item.AprAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.MayCount}</td>
                        <td className="text-right">
                          £
                          {Item.MayAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.JunCount}</td>
                        <td className="text-right">
                          £
                          {Item.JunAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.JulCount}</td>
                        <td className="text-right">
                          £
                          {Item.JulAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.AugCount}</td>
                        <td className="text-right">
                          £
                          {Item.AugAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.SepCount}</td>
                        <td className="text-right">
                          £
                          {Item.SepAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.OctCount}</td>
                        <td className="text-right">
                          £
                          {Item.OctAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.NovCount}</td>
                        <td className="text-right">
                          £
                          {Item.NovAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.DecCount}</td>
                        <td className="text-right">
                          £
                          {Item.DecAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">{Item.TotalCount}</td>
                        <td className="text-right">
                          £
                          {Item.TotalAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      </div>
    </>
  );
}

export default JobReviewMonthlyStats;
