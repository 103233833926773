import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import axiosAuth from "../Axios/Interceptors";
import { useGlobalState } from "../Context/GlobalStateContext";
import { useNavigate } from "react-router-dom";
function Login() {
  const globalState = useGlobalState();
  const navigate = useNavigate();
  const [isTwoFactorAuth, setIsTwoFactorAuth] = useState(false);
  const [passCode, setPassCode] = useState("");
  const [passCodeMessage, setPassCodeMessage] = useState("");
  const [isInvalidCredentials, setIsInvalidCredentials] = useState(false);
  const [isInvalidCredentialsMsg, setIsInvalidCredentialsMsg] = useState("");
  const [isAnimation, setIsAnimation] = useState(false);
  const [isOkToGo, setIsOkToGo] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [passCodeErrorMessage, setPassCodeErrorMessage] = useState(
    "please enter code to continue."
  );
  const [loginObject, setLoginObject] = useState<any>({
    Email: "",
    Password: "",
  });

  useEffect(() => {
    checkValidation(false);
  }, [loginObject]);

  function checkValidation(takeAction: boolean) {
    if (loginObject.Email === "" || loginObject.Password === "") {
      if (takeAction) {
        setShowErrorMessage(true);
      }
      setIsOkToGo(false);
    } else {
      setIsOkToGo(true);
      setShowErrorMessage(false);
      if (takeAction) {
        getUserLoggedIn();
      }
    }
    setIsInvalidCredentials(false);
  }

  function getUserLoggedIn() {
    setIsAnimation(true);
    let object = {} as any;
    object.username = loginObject.Email;
    object.password = loginObject.Password;
    object.crdsvvl = AppContext.GetCookie("crdsvvl");
    axiosAuth
      .post(AppContext.APIURL + "api/SiteUser/GetUserLoggedIn", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data;
          if (l_response.Description === "") {
            AppContext.SetCookie("Token", l_response.Data[0].Token, 480);
            AppContext.SetCookie(
              "RefreshToken",
              l_response.Data[0].RefreshToken,
              600
            );

            AppContext.SetClientDetails();
            navigate("/employeeview");
          } else if (l_response.Description === "CodeRequired") {
            setIsTwoFactorAuth(true);
            setIsOkToGo(false);
            setShowErrorMessage(false);
            setPassCodeMessage(l_response.Message);
          }
        } else {
          setIsInvalidCredentials(true);
          setIsInvalidCredentialsMsg(response.data.Message);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  useEffect(() => {
    checkCodeValidation(false);
    setIsInvalidCredentials(false);
  }, [passCode]);

  function checkCodeValidation(takeAction: boolean) {
    if (passCode === "") {
      if (takeAction) {
        setShowErrorMessage(true);
        setPassCodeErrorMessage("please enter code to continue.");
      }
      setIsOkToGo(false);
    } else {
      setIsOkToGo(true);
      setShowErrorMessage(false);
      if (takeAction) {
        getUserLoggedInWithTwoFactorAuth();
      }
    }
  }

  function getUserLoggedInWithTwoFactorAuth() {
    setIsAnimation(true);
    let object = {} as any;
    object.username = loginObject.Email;
    object.password = loginObject.Password;
    object.passcode = passCode;
    object.crdsvvl = AppContext.GetCookie("crdsvvl");
    setShowErrorMessage(false);
    axiosAuth
      .post(
        AppContext.APIURL + "api/SiteUser/GetUserLoggedInWithTwoFactorAuth",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data;
          AppContext.SetCookie("Token", l_response.Data[0].Token, 30);
          AppContext.SetCookie(
            "RefreshToken",
            l_response.Data[0].RefreshToken,
            600
          );

          AppContext.SetClientDetails();
          navigate("/employeeview");
        } else {
          setShowErrorMessage(true);
          setPassCodeErrorMessage(response.data.Message);
          console.error("error", response.data.Message);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function onEnterPressLoggedIn(e: any) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      checkValidation(true);
    }
  }

  function onEnterPressLoggedInTwoFactor(e: any) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      checkCodeValidation(true);
    }
  }

  return (
    <>
      {isAnimation && <LoadingAnimation />}
      <div className="login-fields inline-block-d text-center width-100">
        {!isTwoFactorAuth ? (
          <>
            <div className="input-center">
              <h1 className="login-heading">Login</h1>
              <input
                type="text"
                className="input-field flex-d width-100"
                placeholder="Email address"
                value={loginObject.Email}
                onChange={(e) =>
                  setLoginObject((prevState: any) => ({
                    ...prevState,
                    Email: e.target.value,
                  }))
                }
              />
              <div className="flex-d width-100 input-gap"></div>
              <div className="p-relative width-100">
                <input
                  type={showPassword ? "text" : "password"}
                  className="input-field flex-d width-100"
                  placeholder="Password"
                  value={loginObject.Password}
                  onChange={(e) =>
                    setLoginObject((prevState: any) => ({
                      ...prevState,
                      Password: e.target.value,
                    }))
                  }
                  onKeyDown={(e) => onEnterPressLoggedIn(e)}
                />
                <a
                  onClick={() => setShowPassword(!showPassword)}
                  className="show-hide-pass"
                >
                  {!showPassword && (
                    <img
                      src="https://dft8zj8i6wy63.cloudfront.net/mnv/images/eye.svg"
                      width="18"
                      height="14"
                      alt="Eye"
                    />
                  )}
                  {showPassword && (
                    <img
                      src="https://dft8zj8i6wy63.cloudfront.net/mnv/images/eye-slash.png"
                      width="18"
                      height="14"
                      alt="Eye-Slash"
                    />
                  )}
                </a>
              </div>
              <div className="login-btn flex-dj width-100">
                <a
                  className={`btn-primary flex-daj  ${
                    !isOkToGo ? "btn-disabled" : ""
                  }`}
                  onClick={() => checkValidation(true)}
                >
                  Login
                </a>
              </div>
              {isInvalidCredentials && (
                <span className="error-message flex-dj">
                  {isInvalidCredentialsMsg}
                </span>
              )}
              {showErrorMessage && (
                <span className="error-message flex-dj">
                  You need to fill all the information to login.
                </span>
              )}
              <div className="forgot-link flex-dj width-100">
                <a href="/forgotpassword" className="c-purple">
                  Forgotten password?
                </a>
              </div>
            </div>
            <span className="txt-register flex-dj">
              If you dont have a login yet, you can register here.
            </span>
            <div className="register-btn flex-dj">
              <a className="btn-secondary flex-daj" href="/signup">
                Register
              </a>
            </div>
          </>
        ) : (
          <>
            <div className="input-center">
              <span className="error-message mt-50 mb-20 flex-dj">
                {passCodeMessage}
              </span>
              <input
                type="text"
                className="input-field flex-d width-100"
                placeholder="Code"
                value={passCode}
                onChange={(e) => setPassCode(e.target.value)}
                onKeyDown={(e) => onEnterPressLoggedInTwoFactor(e)}
              />
              <div className="flex-d width-100 input-gap"></div>

              <div className="login-btn flex-dj width-100">
                <a
                  className={`btn-primary flex-daj  ${
                    !isOkToGo ? "btn-disabled" : ""
                  }`}
                  onClick={() => checkCodeValidation(true)}
                >
                  Login
                </a>
              </div>
              {isInvalidCredentials && (
                <span className="error-message flex-dj">
                  {isInvalidCredentialsMsg}
                </span>
              )}
              {showErrorMessage && (
                <span className="error-message flex-dj">
                  {passCodeErrorMessage}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Login;
