import AppContext from "../Context/Context";
import $ from "jquery";
import * as XLSX from "xlsx";
export function WriteAndDownloadFile(filename: string, content: string) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(content)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export async function getFileFromUrl(
  url: any,
  name: any,
  defaultType = "image/jpeg"
) {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export function ConvertSecondsIntoDayTimeFormat(Seconds: any) {
  var DisplayTime = "";
  var TimeDistance = Seconds * 1000; // Convert to ms
  if (Seconds <= 0) {
    DisplayTime = "0d:0h:0m:0s";
  } else {
    //calculations for days, hours, minutes and seconds
    var days = Math.floor(TimeDistance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (TimeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((TimeDistance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((TimeDistance % (1000 * 60)) / 1000);

    DisplayTime = days + "d " + hours + "h " + minutes + "m " + seconds + "s";
  }
  return DisplayTime;
}

export function ConvertSecondsIntoTimeFormat(Seconds: any) {
  var DisplayTime = "";
  var TimeDistance = Seconds * 1000; // Convert to ms
  if (Seconds <= 0) {
    DisplayTime = "00:00:00";
  } else {
    //calculations for days, hours, minutes and seconds
    var days = Math.floor(TimeDistance / (1000 * 60 * 60 * 24));
    var hours: any = Math.floor(
      (TimeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes: any = Math.floor(
      (TimeDistance % (1000 * 60 * 60)) / (1000 * 60)
    );
    var seconds: any = Math.floor((TimeDistance % (1000 * 60)) / 1000);

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    DisplayTime = hours + ":" + minutes + ":" + seconds;
  }
  return DisplayTime;
}

export function SetSelectedTabBrand(Tab: number, Brand: number) {
  AppContext.selectedTabBrand[Tab] = Brand;
}

export function GetSelectedTabBrand(Tab: number) {
  if (AppContext.selectedTabBrand[0] === 0) {
    return AppContext.selectedTabBrand[Tab];
  } else {
    return AppContext.selectedTabBrand[0];
  }
}

export function GetSelectedCurrencyType(Tab: number) {
  if (AppContext.selectedCurrencyType[0] === 1) {
    return AppContext.selectedCurrencyType[Tab];
  } else {
    return AppContext.selectedCurrencyType[0];
  }
}

export function GetSelectedTabBrandName(Tab: number) {
  if (AppContext.selectedTabBrand[0] === 0) {
    if (AppContext.selectedTabBrand[Tab] === 3) {
      return "Jaguar";
    } else {
      return "Land Rover";
    }
  } else {
    if (AppContext.selectedTabBrand[0] === 3) {
      return "Jaguar";
    } else {
      return "Land Rover";
    }
  }
}

export function getCategoryClass(p_intValue: number) {
  var l_strClass = "";

  var m_intMinValueCategorySubD = -10; //0;
  var m_intMaxValueCategorySubD = -10; // 150;

  var m_intMinValueCategoryD = 0; // 151;
  var m_intMaxValueCategoryD = 300;

  var m_intMinValueCategoryC = 301;
  var m_intMaxValueCategoryC = 500;

  var m_intMinValueCategoryB = 501;
  var m_intMaxValueCategoryB = 800;

  var m_intMinValueCategoryA = 801;
  var m_intMaxValueCategoryA = 999999999;

  if (p_intValue <= m_intMaxValueCategorySubD) l_strClass = "sub-d";
  else if (
    p_intValue >= m_intMinValueCategoryD &&
    p_intValue <= m_intMaxValueCategoryD
  )
    l_strClass = "cat-d";
  else if (
    p_intValue >= m_intMinValueCategoryC &&
    p_intValue <= m_intMaxValueCategoryC
  )
    l_strClass = "cat-c";
  else if (
    p_intValue >= m_intMinValueCategoryB &&
    p_intValue <= m_intMaxValueCategoryB
  )
    l_strClass = "cat-b";
  else l_strClass = "cat-a";
  return l_strClass;
}

//////////// AR: Checkboxes/RadioButtons functions starts /////////

export function GetSelectedIDsFromList(p_strID: any) {
  var l_intSelectedIDs = "-1";
  var l_strList = document.getElementsByName(p_strID) as any;

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (l_strList[i].checked) {
      l_intSelectedIDs = l_intSelectedIDs + "," + l_strList[i].value;
    }
  }
  l_intSelectedIDs = l_intSelectedIDs.replace("-1,", "");
  return l_intSelectedIDs;
}

export function SelectUnSelectSpecificElement(
  p_strID: any,
  p_intValue: any,
  p_boolSelect: any
) {
  var l_strList: any = document.getElementsByName(p_strID);

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (p_intValue == l_strList[i].value) {
      l_strList[i].checked = p_boolSelect;
    }
  }
}

export function SelectUnSelectAllElement(p_strID: any, p_boolChecked: any) {
  var l_strList: any = document.getElementsByName(p_strID);

  for (var i = 0, length = l_strList.length; i < length; i++) {
    l_strList[i].checked = p_boolChecked;
  }
}

export function SelectUnselectAllButOne(
  p_strID: any,
  p_intValue: any,
  p_boolSelect: any
) {
  var l_strList: any = document.getElementsByName(p_strID);

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (p_intValue == l_strList[i].value) {
      l_strList[i].checked = p_boolSelect;
    } else {
      l_strList[i].checked = !p_boolSelect;
    }
  }
}

export function CheckIfSelectedAnyElement(p_strID: any) {
  var l_boolIsSelectedAny = false;
  var l_strList: any = document.getElementsByName(p_strID);

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (l_strList[i].checked) {
      l_boolIsSelectedAny = true;
    }
  }
  return l_boolIsSelectedAny;
}

export function CheckIfSelectedAllElement(p_strID: any) {
  var l_boolIsSelectedAll = true;
  var l_strList: any = document.getElementsByName(p_strID);

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (l_strList[i].checked) {
    } else {
      l_boolIsSelectedAll = false;
    }
  }
  return l_boolIsSelectedAll;
}

//////////// AR: Checkboxes/RadioButtons functions ends /////////

//////////// AR: Select / unselect  starts /////////

export function SelectUnSelectPTItem(
  chkSelectUnSelect: any,
  chkSelectUnSelectAll: any
) {
  if (CheckIfSelectedAllElement(chkSelectUnSelect)) {
    let element: any = document.getElementById(chkSelectUnSelectAll);
    element.checked = true;
  } else {
    let element: any = document.getElementById(chkSelectUnSelectAll);
    element.checked = false;
  }
}

export function SelectUnSelectAll(
  chkSelectUnSelectAll: any,
  chkSelectUnSelect: any
) {
  let element: any = document.getElementById(chkSelectUnSelectAll);
  SelectUnSelectAllElement(chkSelectUnSelect, element.checked);
}

//////////// AR: Select / unselect  ends /////////

export function SortTheList(List: any, SortBy: any, Direction: any) {
  if (Direction == "A") return List.sort(GetSortOrderAsc(SortBy));
  else return List.sort(GetSortOrderDesc(SortBy));
}

function GetSortOrderAsc(prop: any) {
  return function (a: any, b: any) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function GetSortOrderDesc(prop: any) {
  return function (a: any, b: any) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

export function getUniqueRecordsAgainstNthColumn(
  p_oGridData: any,
  p_strColumnName: any
) {
  var lookup = {} as any;
  var l_oArrayOfUniqueValues = [];
  for (var item, i = 0; (item = p_oGridData[i++]); ) {
    var name = item[p_strColumnName];
    if (name != "" || name == 0) {
      if (!(name in lookup)) {
        lookup[name] = 1;
        l_oArrayOfUniqueValues.push(name);
      }
    }
  }
  return l_oArrayOfUniqueValues.sort();
}

export function checkIfItemIsIntheList(p_strValue: string, l_oValueList: any) {
  let hasValueIntheList = false;
  if ($.inArray(p_strValue, l_oValueList) > -1) {
    hasValueIntheList = true;
  }
  return hasValueIntheList;
}



export function currentYearDisplay() {
  const currentYear = new Date().getFullYear();
  return currentYear;
}
export function SanitizeHtml(html: any) {
  var tagOrComment =
    /<(?:!--[\s\S]*?--|script[\s\S]*?<\/script[^>]*>|style[\s\S]*?<\/style[^>]*|\/?[a-z][^>]*?)>/gi;
  return html.replace(tagOrComment, "").replace(/</g, "&lt;");
}


export function addCommas(num: any) {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
}

export function removeNonNumeric(num: any) {
  if (num) {
    return num.toString().replace(/[^0-9.]/g, "");
  } else {
    return "";
  }
}

export function removeNonNumericAndConvertIntoNumber(num: any) {
  let value = num.toString().replace(/[^0-9]/g, "");
  return parseInt(value);
}


export const getStartOfMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1).toLocaleDateString("en-CA"); 
};

export const getEndOfMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).toLocaleDateString("en-CA"); 
};


export const getCurrentMonthNumber= () => {
  const now = new Date();
  return now.getMonth() + 1; // getMonth() is zero-based, so we add 1
}


// Download in Excel function
export function DownloadClientsDataInExcel(id: any, name: any) {
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
    document.getElementById(id)
  );
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, name);

  XLSX.writeFile(wb, name + ".xlsx");
}

// Download multiple tables in Excel function
export function DownloadMultipleClientsDataInExcel(ids: string, name: string) {
  if (!ids || !name) {
    console.error("Invalid IDs or name provided.");
    return;
  }

  const idArray = ids.split(",").map((id) => id.trim());
  const data: any[][] = []; // Array to store combined data

  idArray.forEach((id) => {
    const tableElement = document.getElementById(id);
    if (tableElement) {
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tableElement);
      const tableData:any = XLSX.utils.sheet_to_json(ws, { header: 1 }); // Convert sheet to JSON array
      data.push([`${id}`]); // Add the table ID as a header
      data.push(...tableData, []); // Append table data with a blank row for separation
    } else {
      console.warn(`Table with ID "${id}" not found.`);
    }
  });

  if (data.length === 0) {
    console.error("No data found to export.");
    return;
  }

  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data); // Convert combined data to sheet
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "CombinedData");

  XLSX.writeFile(wb, `${name}.xlsx`);
}

export function DownloadClientsDataInExcelWithoutDisplayNone(
  id: string,
  name: string
) {
  const table = document.getElementById(id);
  if (!table) return;

  // Clone the table to avoid modifying the original table
  const clonedTable = table.cloneNode(true) as HTMLTableElement;

  // Remove all `tr`, `th`, and `td` elements with `display: none`
  const rows = clonedTable.querySelectorAll("tr");
  rows.forEach((row) => {
    if (row.style.display === "none") {
      row.remove();
    } else {
      const headers = row.querySelectorAll("th");
      headers.forEach((header) => {
        if (header.style.display === "none") {
          header.remove();
        }
      });
      const cells = row.querySelectorAll("td");
      cells.forEach((cell) => {
        if (cell.style.display === "none") {
          cell.remove();
        } else {
          // If cell contains an input element, replace cell content with input value
          const input = cell.querySelector("input");
          if (input) {
            cell.textContent = input.value;
          }
        }
      });
    }
  });

  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(clonedTable);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, name);

  XLSX.writeFile(wb, name + ".xlsx");
}