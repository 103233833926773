import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import axiosAuth from "../Axios/Interceptors";
import moment from "moment";
import {
  DownloadClientsDataInExcel,
  DownloadMultipleClientsDataInExcel,
  getEndOfMonth,
  getStartOfMonth,
} from "../Shared/Utility";
import ConfirmationModal from "../App/ConfirmationModal";
import { useGlobalState } from "../Context/GlobalStateContext";
import DragDrop from "../DragDrop/DragDrop";
function EmployeeView() {
  const globalState = useGlobalState();
  const [isAnimation, setIsAnimation] = useState(false);

  const [moversList, setMoversList] = useState<any>([]);
  const [rateTypeData, setRateTypeData] = useState<any>([]);
  const [totalsData, setTotalsData] = useState<any>([]);
  const [employeeJobData, setEmployeeJobData] = useState<any>([]);
  const [employeeBonusAndDeductionsData, setEmployeeBonusAndDeductionsData] =
    useState<any>([]);
  const [employeeReviewData, setEmployeeReviewData] = useState<any>([]);

  const [filterData, setFilterData] = useState({
    NumericYear: new Date().getFullYear(),
    NumericMonth: new Date().getMonth() + 1,
    MNVMoverID: AppContext.MNVMoverID,
    ShowJobData: true,
    ShowBonusAndDeductionsData: false,
    ShowReviewsData: false,
    IsFirstTime: true,
  });

  useEffect(() => {
    getJobReviewGetJobDetailsAndMoversList(1);
  }, []);

  useEffect(() => {
    if (filterData.MNVMoverID > 0 && filterData.IsFirstTime) {
      setFilterData((prevState: any) => ({
        ...prevState,
        IsFirstTime: false,
      }));
      MNVMoverJobDataGetEmployeeViewData();
    }
  }, [filterData]);

  function getJobReviewGetJobDetailsAndMoversList(typeid: any) {
    setIsAnimation(true);
    let object = {} as any;
    object.typeid = typeid;
    object.screenname = "employeeview";
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVJobReview/MNVJobReviewGetJobDetailsAndMoversList",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          if (typeid == 1) {
            let filtered = l_oResponse[1].filter(
              (item: any) => item.IsContractor == false
            );
            setMoversList(filtered);
            if (filtered.length > 0 && AppContext.MNVMoverID == 0) {
              setFilterData((prevState: any) => ({
                ...prevState,
                MNVMoverID: filtered[0]["MNVMoverID"],
              }));
            }
            else
            {
              setFilterData((prevState: any) => ({
                ...prevState,
                MNVMoverID: AppContext.MNVMoverID,
              }));
            }
          }
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function MNVMoverJobDataGetEmployeeViewData() {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvmoverid = filterData.MNVMoverID;
    object.numericyear = filterData.NumericYear;
    object.numericmonth = filterData.NumericMonth;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVMoverJobData/MNVMoverJobDataGetEmployeeViewData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          setRateTypeData(l_oResponse[0]);
          setTotalsData(l_oResponse[1]);
          setEmployeeJobData(l_oResponse[2]);
          setEmployeeBonusAndDeductionsData(l_oResponse[3]);
          setEmployeeReviewData(l_oResponse[4]);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function getSelectedMNVMoverName() {
    let filtered = moversList.filter(
      (item: any) => item.MNVMoverID == filterData.MNVMoverID
    );
    if (filtered.length > 0) return filtered[0].Name;
    else return "Employee";
  }

  return (
    <div className="inline-block-d width-100 p-all10">
      {isAnimation && <LoadingAnimation />}
      <>
        <div className="flex-d justify-btw mb-10 m-f-wrap1">
          <div className="flex-da gap-10 m-f-wrap2">
            <div className="field-input">
              <select
                className="input-field width-auto"
                value={filterData.NumericYear}
                onChange={(e) =>
                  setFilterData((prevState: any) => ({
                    ...prevState,
                    NumericYear: e.target.value,
                  }))
                }
              >
                {/* <option value={0}>Select Year</option> */}
                {AppContext.yearsList &&
                  AppContext.yearsList.map((item: any, index: any) => (
                    <React.Fragment key={index}>
                      <option value={item.ID}>{item.Name}</option>
                    </React.Fragment>
                  ))}
              </select>
            </div>

            <div className="field-input">
              <select
                className="input-field width-auto"
                value={filterData.NumericMonth}
                onChange={(e) =>
                  setFilterData((prevState: any) => ({
                    ...prevState,
                    NumericMonth: e.target.value,
                  }))
                }
              >
                {/* <option value={0}>Select Month</option> */}
                {AppContext.monthsList &&
                  AppContext.monthsList.map((item: any, index: any) => (
                    <React.Fragment key={index}>
                      <option value={item.ID}>{item.Name}</option>
                    </React.Fragment>
                  ))}
              </select>
            </div>

            {AppContext.MNVMoverID == 0 && (
              <div className="field-input">
                <select
                  className="input-field width-auto"
                  value={filterData.MNVMoverID}
                  onChange={(e) => {
                    setFilterData((prevState: any) => ({
                      ...prevState,
                      MNVMoverID: e.target.value,
                    }));
                  }}
                >
                  {moversList &&
                    moversList.map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <option value={item.MNVMoverID}>{item.Name}</option>
                      </React.Fragment>
                    ))}
                </select>
              </div>
            )}

            <div className="flex-daj btn-start">
              <a
                className="btn-primary flex-daj"
                onClick={() => MNVMoverJobDataGetEmployeeViewData()}
              >
                Filter
              </a>
            </div>
          </div>
          <div className="flex-d gap-10 m-f-wrap3">
            {/* <a
              className="btn-primary flex-daj btn-grid"
              title="Export Job Type Hours"
              onClick={() =>
                DownloadClientsDataInExcel(
                  "Job_Type_Hours",
                  "Job Type Hours " + filterData.NumericYear+ "-"+ AppContext.monthsList[filterData.NumericMonth-1].Name
                )
              }
            >
              <img src="images/export.svg" width="18" height="18" />
            </a>


            <a
              className="btn-primary flex-daj btn-grid"
              title="Export Totals"
              onClick={() =>
                DownloadClientsDataInExcel(
                  "Totals",
                  "Totals " + filterData.NumericYear+ "-"+ AppContext.monthsList[filterData.NumericMonth-1].Name
                )
              }
            >
              <img src="images/export.svg" width="18" height="18" />
            </a> */}

            <a
              className="btn-primary flex-daj btn-grid"
              title="Export"
              onClick={() =>
                DownloadMultipleClientsDataInExcel(
                  "Job_Type_Hours,Totals",
                  getSelectedMNVMoverName() +
                    "_" +
                    AppContext.monthsList[filterData.NumericMonth - 1].Name +
                    "-" +
                    filterData.NumericYear
                )
              }
            >
              <img src="images/export.svg" width="18" height="18" />
            </a>
            <a
              className="btn-primary flex-daj btn-grid"
              title="Export All Tables"
              onClick={() =>
                DownloadMultipleClientsDataInExcel(
                  "Job_Type_Hours,Totals,Job_Data,Bonus_And_Deduction,Review_Data",
                  getSelectedMNVMoverName() +
                    "_" +
                    AppContext.monthsList[filterData.NumericMonth - 1].Name +
                    "-" +
                    filterData.NumericYear
                )
              }
            >
              <img src="images/export.svg" width="18" height="18" />
            </a>
          </div>
        </div>

        <div className="grid-d fr-21 gap-20">
          <div className="flow-auto">
            <table className="reuse-table" id="Job_Type_Hours">
              <thead>
                <tr>
                  <th>Job Type</th>
                  <th>Hours</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {rateTypeData &&
                  rateTypeData.map((item: any, index: any) => (
                    <React.Fragment key={"emp" + index}>
                      <tr key={index}>
                        <td>{item.MNVEmployeeRateTypeName}</td>
                        <td className="text-right">
                          {item.TotalHours?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">
                          £
                          {item.TotalAmount?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>

          {totalsData.length > 0 && (
            <div className="flow-auto">
              <table className="reuse-table" id="Totals">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Hourly Totals</b>
                    </td>
                    <td className="text-right">
                      <b>
                        £
                        {totalsData[0].HourlyTotals?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Bonus</b>
                    </td>
                    <td className="text-right">
                      <b>
                        £
                        {totalsData[0].Bonus?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Review Bonus</b>
                    </td>
                    <td className="text-right">
                      <b>
                        £
                        {totalsData[0].ReviewBonus?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Stairs</b>
                    </td>
                    <td className="text-right">
                      <b>
                        {" "}
                        £
                        {totalsData[0].Stairs?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Gross Total</b>
                    </td>
                    <td className="text-right">
                      <b>
                        £
                        {(
                          totalsData[0].HourlyTotals +
                          totalsData[0].Bonus +
                          totalsData[0].ReviewBonus +
                          totalsData[0].Stairs
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Work Deductions</b>
                    </td>
                    <td className="text-right">
                      <b>
                        {" "}
                        £
                        {totalsData[0].WorkDeductions?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Overall Total</b>
                    </td>
                    <td className="text-right">
                      <b>
                        £
                        {(
                          totalsData[0].HourlyTotals +
                          totalsData[0].Bonus +
                          totalsData[0].ReviewBonus +
                          totalsData[0].Stairs -
                          totalsData[0].WorkDeductions
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </b>
                    </td>
                  </tr>
                  {/* <tr>
                    <td></td>
                    <td></td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <a
          className="accordion-top mt-10 flex-da justify-btw"
          onClick={() => {
            let filterDataLocal = filterData;
            filterDataLocal.ShowJobData = !filterDataLocal.ShowJobData;
            setFilterData((prevState: any) => ({
              ...prevState,
              ShowJobData: filterDataLocal.ShowJobData,
            }));
          }}
        >
          Job Data
          <img
            className={`name-count ${
              filterData.ShowJobData ? "rotate-90n" : "rotate-90"
            }`}
            src="/images/chev-right-w.svg"
            width={9}
            height={15}
          />
        </a>
        {/* {filterData.ShowJobData && ( */}
        <span className={filterData.ShowJobData ? "" : "no-display"}>
          <div className="flex-d justify-end mb-10 gap-10">
            <a
              className="btn-primary flex-daj btn-grid"
              title="Export"
              onClick={() =>
                DownloadClientsDataInExcel(
                  "Job_Data",
                  getSelectedMNVMoverName() +
                    "_" +
                    AppContext.monthsList[filterData.NumericMonth - 1].Name +
                    "-" +
                    filterData.NumericYear
                )
              }
            >
              <img src="images/export.svg" width="18" height="18" />
            </a>
          </div>
          <div className="flow-auto">
            <table className="reuse-table" id="Job_Data">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Job Role</th>
                  <th>Hours</th>
                  <th>Rate Per Hour</th>
                  <th>Stairs</th>
                  {/* <th>Rate Per Stair</th> */}
                  <th>Totals exc Stair</th>
                  <th>Totals inc Stair</th>
                </tr>
              </thead>
              <tbody>
                {employeeJobData &&
                  employeeJobData.map((item: any, index: any) => (
                    <React.Fragment key={"emp" + index}>
                      <tr key={index}>
                        <td>{moment(item.DataDate).format("DD/MM/YYYY")}</td>
                        <td>{item.MNVEmployeeRateTypeName}</td>
                        <td className="text-right">
                          {item.TotalHours?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">
                          £
                          {item.RatePerHour?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">
                          {item.TotalStairs?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        {/* <td className="text-right">
                        £
                        {item.RatePerStair?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td> */}
                        <td className="text-right">
                          £
                          {item.TotalExclStairs?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-right">
                          £
                          {item.TotalAmount?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </span>
        {/* )} */}

        <a
          className="accordion-top mt-10 flex-da justify-btw"
          onClick={() => {
            let filterDataLocal = filterData;
            filterDataLocal.ShowBonusAndDeductionsData =
              !filterDataLocal.ShowBonusAndDeductionsData;
            setFilterData((prevState: any) => ({
              ...prevState,
              ShowBonusAndDeductionsData:
                filterDataLocal.ShowBonusAndDeductionsData,
            }));
          }}
        >
          Bonus And Deductions
          <img
            src="/images/chev-right-w.svg"
            className={`name-count ${
              filterData.ShowBonusAndDeductionsData ? "rotate-90n" : "rotate-90"
            }`}
            width={9}
            height={15}
          />
        </a>
        {/* {filterData.ShowBonusAndDeductionsData && ( */}
        <span
          className={filterData.ShowBonusAndDeductionsData ? "" : "no-display"}
        >
          <div className="flex-d justify-end mb-10 gap-10">
            <a
              className="btn-primary flex-daj btn-grid"
              title="Export"
              onClick={() =>
                DownloadClientsDataInExcel(
                  "Bonus_And_Deduction",
                  getSelectedMNVMoverName() +
                    "_" +
                    AppContext.monthsList[filterData.NumericMonth - 1].Name +
                    "-" +
                    filterData.NumericYear
                )
              }
            >
              <img src="images/export.svg" width="18" height="18" />
            </a>
          </div>
          <div className="flow-auto">
            <table className="reuse-table" id="Bonus_And_Deduction">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Data Type</th>
                  <th>Amount</th>
                  <th>More Information</th>
                </tr>
              </thead>
              <tbody>
                {employeeBonusAndDeductionsData &&
                  employeeBonusAndDeductionsData.map(
                    (item: any, index: any) => (
                      <React.Fragment key={index}>
                        <tr key={index}>
                          <td>{moment(item.DataDate).format("DD/MM/YYYY")}</td>
                          <td>{item.DataTypeName}</td>
                          <td className="text-right">
                            £
                            {item.Amount?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>{item.Reason}</td>
                        </tr>
                      </React.Fragment>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </span>
        {/* )} */}

        <a
          className="accordion-top mt-10 flex-da justify-btw"
          onClick={() => {
            let filterDataLocal = filterData;
            filterDataLocal.ShowReviewsData = !filterDataLocal.ShowReviewsData;
            setFilterData((prevState: any) => ({
              ...prevState,
              ShowReviewsData: filterDataLocal.ShowReviewsData,
            }));
          }}
        >
          Reviews Data
          <img
            src="/images/chev-right-w.svg"
            className={`name-count ${
              filterData.ShowReviewsData ? "rotate-90n" : "rotate-90"
            }`}
            width={9}
            height={15}
          />
        </a>
        {/* {filterData.ShowReviewsData && ( */}
        <span className={filterData.ShowReviewsData ? "" : "no-display"}>
          <div className="flex-d justify-end mb-10 gap-10">
            <a
              className="btn-primary flex-daj btn-grid"
              title="Export"
              onClick={() =>
                DownloadClientsDataInExcel(
                  "Review_Data",
                  getSelectedMNVMoverName() +
                    "_" +
                    AppContext.monthsList[filterData.NumericMonth - 1].Name +
                    "-" +
                    filterData.NumericYear
                )
              }
            >
              <img src="images/export.svg" width="18" height="18" />
            </a>
          </div>
          <div className="flow-auto">
            <table className="reuse-table" id="Review_Data">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {employeeReviewData &&
                  employeeReviewData.map((item: any, index: any) => (
                    <React.Fragment key={index}>
                      <tr key={index}>
                        <td>{moment(item.ReviewDate).format("DD/MM/YYYY")}</td>
                        <td className="text-right">
                          £
                          {item.Amount?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </span>
        {/* )} */}
      </>
    </div>
  );
}

export default EmployeeView;
