import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import AppContext from "../Context/Context";
import { useGlobalState } from "../Context/GlobalStateContext";
export default function RequireAuth({ children }: any) {
  const location = useLocation();
  let completeURL = location.pathname + location.search;
  const [IsUserLoggedIn, setIsUserLoggedIn] = useState(true);
  const [OkToGo, setOkToGo] = useState(false);
  const globalState = useGlobalState();

  useEffect(() => {
    const CheckIsUserLoggedIn = async () => {
      const isLoggedIn = await AppContext.IsUserLoggedIn();
      if (isLoggedIn === true) {
        var token = AppContext.GetCookie("Token");
        if (token && token !== "") {
          SetUserInfo(token);
        }
        // window.location.href = "/home";
        return true;
      } else {
        globalState.setLogInUserName("");
        globalState.setLogInUserImage("");
        globalState.setLogInUserRole("");
        AppContext.LogInUserName = "";
        return false;
      }
    };

    const SetUserInfo = (token: any) => {
      if (token && token !== "") var UserInfo: any = jwt_decode(token);
      if (UserInfo && UserInfo !== "") {
        globalState.setLogInUserName(UserInfo.username);
        globalState.setLogInUserImage(UserInfo.userimage);
        globalState.setLogInUserRole(UserInfo.role);
        AppContext.LogInUserName = UserInfo.username;
      }
    };

    (async () => {
      if ((await CheckIsUserLoggedIn()) === true) {
        // let havePermission = await AppContext.CheckAccessAndSettingsNDT(
        //   completeURL
        // );
        // if (havePermission) {
        //   setIsUserLoggedIn(true);
        // } else {
        //   setIsUserLoggedIn(false);
        // }

        let ReturnData = await AppContext.GetLoggedinUserRoleDetails();
        if (ReturnData && ReturnData[0] && ReturnData[0].length > 0) {
          globalState.setMNVMoverID(ReturnData[0][0].MNVMoverID);
          globalState.setIsContractor(ReturnData[0][0].IsContractor);
          AppContext.MNVMoverID = ReturnData[0][0].MNVMoverID;
          AppContext.IsContractor = ReturnData[0][0].IsContractor;

          let IsSemiAdmin = false;
          let IsBlogAdmin = false;

          let RoleName = ReturnData[0][0]["RoleName"];
          if (RoleName == "Semi-Admin") IsSemiAdmin = true;
          else if (RoleName == "Blog Admin") IsBlogAdmin = true;

          AppContext.IsSemiAdmin = IsSemiAdmin;
          AppContext.IsBlogAdmin = IsBlogAdmin;
        }

        if (ReturnData && ReturnData[1] && ReturnData[1].length > 0) {
          globalState.setRolesList(ReturnData[1][0]);
        }

        // check if its not admin
        if (AppContext.MNVMoverID > 0) {
          //check if its employee or contractor
          if (AppContext.IsContractor == false) {
            let isEmployeeScreens = false;
            for (
              let index = 0;
              index < AppContext.EmployeeScreens.length;
              index++
            ) {
              if (AppContext.EmployeeScreens[index] == completeURL) {
                isEmployeeScreens = true;
              }
            }
            if (isEmployeeScreens == false) {
              window.location.href = "/employeeview";
            }
          } else {
            //handle contractor screens here
            window.location.href = "/notaccess";
          }
        }

        if (AppContext.IsBlogAdmin == true) window.location.href = "/notaccess";

        setIsUserLoggedIn(true);
      } else {
        setIsUserLoggedIn(false);
      }
      setOkToGo(true);
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  let ReturnData = "" as any;
  if (IsUserLoggedIn === true && OkToGo) {
    ReturnData = children;
  } else if (IsUserLoggedIn === false && OkToGo) {
    window.location.href = "/login";
  }
  return ReturnData;
}
