import { Link, NavLink } from "react-router-dom";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import Notification from "./Notification";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
export default function Header() {
  const globalState = useGlobalState();
  const [dataArray, setDataArray] = useState([]);
  const [ShowFinance, SetShowFinance] = useState(false);
  const ShowHideFinance = () => {
    SetShowFinance(!ShowFinance);
  };
  const [ShowEmployee, SetShowEmployee] = useState(true);
  const ShowHideEmployee = () => {
    SetShowEmployee(!ShowEmployee);
  };
  const [ShowGeneral, SetShowGeneral] = useState(false);
  const ShowHideGeneral = () => {
    SetShowGeneral(!ShowGeneral);
  };
  const [ShowWebsite, SetShowWebsite] = useState(false);
  const ShowHideWebsite = () => {
    SetShowWebsite(!ShowWebsite);
  };
  const [ShowConfiguration, SetShowConfiguration] = useState(false);
  const ShowHideConfiguration = () => {
    SetShowConfiguration(!ShowConfiguration);
  };

  const [ShowLeftMenu, SetShowLeftMenu] = useState(false);
  const ShowHideLeftMenu = () => {
    SetShowLeftMenu(!ShowLeftMenu);
  };

  useEffect(() => {}, []);

  const LogOutUser = () => {
    AppContext.SetCookie("Token", "", 0);
    AppContext.SetCookie("RefreshToken", "", 0);
    globalState.setLogInUserName("");
    globalState.setLogInUserImage("");
    globalState.setLogInUserRole("");
    AppContext.LogInUserName = "";
    window.location.href = "/login";
  };

  function NotificationCallBack() {
    globalState.setNotificationObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
    });
  }

  return (
    <>
      <Notification
        NotificationObject={globalState.NotificationObject}
        NotificationCallBack={NotificationCallBack}
      />
      {globalState.LogInUserName !== "" && (
        <header className="flex-daj header">
          <div className="flex-da justify-btw reuse-width header-content">
            <div className="flex-d">
              <a className="flex-d show-980" onClick={ShowHideLeftMenu}>
                <img
                  className={`drop-icon ${ShowEmployee ? "rotate-open" : ""}`}
                  src="images/menu-bars.svg"
                  width={18}
                  height={16}
                />
              </a>
            </div>
            <nav className="nav-menu flex-da">
              {/* {globalState.LogInUserName === "" && ( */}
              <div className="nav-item p-relative">
                <div className="sep-click">
                  <Link className="flex-da click-link" to={""}>
                    {globalState.LogInUserName}
                    <img
                      src="/images/arrow-down.svg"
                      width="9"
                      height="17"
                      alt="arrow down"
                      // className="hide-980"
                    />
                  </Link>
                  {/* <a className="flex-da click-open">
                    <img
                      src="/images/arrow-down.svg"
                      width="9"
                      height="17"
                      alt="arrow down"
                      className="show-980"
                    />
                  </a> */}
                </div>
                <div className="sub-menu">
                  <Link to={""}>
                    <span className="show-980"></span>
                    <p>
                      {" "}
                      <a onClick={LogOutUser}>LogOut</a>
                    </p>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </header>
      )}

      {globalState.LogInUserName !== "" && (
        <div
          className={`left-menu custom-scroll ${
            ShowLeftMenu ? "Show-left-menu" : ""
          }`}
        >
          <div className="left-menu-container">
            {AppContext.MNVMoverID == 0 && AppContext.IsBlogAdmin == false && (
              <>
                <div className="left-menu-items">
                  <a
                    className="flex-da click-link flex-jsb"
                    href="https://app.finestvan.co.uk/dashboard"
                  >
                    <span>Dashboard</span>
                  </a>
                  <a
                    className="flex-da click-link flex-jsb"
                    href="https://app.finestvan.co.uk/calendar"
                  >
                    <span>Scheduling</span>
                  </a>
                  <a
                    className="flex-da click-link flex-jsb"
                    href="https://app.finestvan.co.uk/allquotes"
                  >
                    <span>Quotes</span>
                  </a>
                </div>

                <div className="left-menu-items">
                  <a
                    className="flex-da click-link flex-jsb"
                    onClick={ShowHideFinance}
                  >
                    <span>Finance</span>
                    <img
                      className={`drop-icon ${
                        ShowFinance ? "rotate-open" : ""
                      }`}
                      src="images/chev-down.svg"
                      width={10}
                      height={10}
                    />
                  </a>
                  {ShowFinance && AppContext.MNVMoverID == 0 && (
                    <div className="left-sub-items">
                      {AppContext.IsSemiAdmin == false && (
                        <>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/earningsdashboard"
                          >
                            <span>Earnings</span>
                          </a>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/tippingdashboard"
                          >
                            <span>Tipping</span>
                          </a>
                        </>
                      )}
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/invoicedata"
                      >
                        <span>Invoices</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/receiptsdata"
                      >
                        <span>Receipts</span>
                      </a>
                    </div>
                  )}
                </div>

                <div className="left-menu-items">
                  <a
                    className="flex-da click-link flex-jsb"
                    onClick={ShowHideEmployee}
                  >
                    <span>Employee</span>
                    <img
                      className={`drop-icon ${
                        ShowEmployee ? "rotate-open" : ""
                      }`}
                      src="images/chev-down.svg"
                      width={10}
                      height={10}
                    />
                  </a>
                  {ShowEmployee &&
                    (!AppContext.IsContractor ||
                      AppContext.MNVMoverID == 0) && (
                      <div className="left-sub-items">
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? "flex-da click-link active"
                              : "flex-da click-link"
                          }
                          to={"/employeetimelog"}
                        >
                          Time Entry
                        </NavLink>

                        {AppContext.MNVMoverID == 0 && (
                          <>
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? "flex-da click-link active"
                                  : "flex-da click-link"
                              }
                              to={"/employeebonusanddeductions"}
                            >
                              Bonus & Deductions
                            </NavLink>
                          </>
                        )}

                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? "flex-da click-link active"
                              : "flex-da click-link"
                          }
                          to={"/employeeview"}
                        >
                          Month Overview
                        </NavLink>

                        {AppContext.MNVMoverID == 0 && (
                          <>
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? "flex-da click-link active"
                                  : "flex-da click-link"
                              }
                              to={"/employeesummary"}
                            >
                              Year Overview
                            </NavLink>
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? "flex-da click-link active"
                                  : "flex-da click-link"
                              }
                              to={"/employeerates"}
                            >
                              Rates Payable
                            </NavLink>
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? "flex-da click-link active"
                                  : "flex-da click-link"
                              }
                              to={"/jobreview"}
                            >
                              Online Reviews
                            </NavLink>
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? "flex-da click-link active"
                                  : "flex-da click-link"
                              }
                              to={"/jobreviewmonthlystats"}
                            >
                              Online Reviews History
                            </NavLink>
                            <a
                              className="flex-da click-link flex-jsb"
                              href="https://app.finestvan.co.uk/marketingscore"
                            >
                              <span>Marketing Score</span>
                            </a>
                          </>
                        )}
                      </div>
                    )}
                </div>

                <div className="left-menu-items">
                  <a
                    className="flex-da click-link flex-jsb"
                    onClick={ShowHideGeneral}
                  >
                    <span>General</span>
                    <img
                      className={`drop-icon ${
                        ShowGeneral ? "rotate-open" : ""
                      }`}
                      src="images/chev-down.svg"
                      width={10}
                      height={10}
                    />
                  </a>
                  {ShowGeneral && AppContext.MNVMoverID == 0 && (
                    <div className="left-sub-items">
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/clients"
                      >
                        <span>Clients</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/support"
                      >
                        <span>Support</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/myconversations"
                      >
                        <span>Messages</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/waconversations"
                      >
                        <span>WhatsApp Messages</span>
                      </a>
                    </div>
                  )}
                </div>

                <div className="left-menu-items">
                  <a
                    className="flex-da click-link flex-jsb"
                    onClick={ShowHideWebsite}
                  >
                    <span>Website</span>
                    <img
                      className={`drop-icon ${
                        ShowWebsite ? "rotate-open" : ""
                      }`}
                      src="images/chev-down.svg"
                      width={10}
                      height={10}
                    />
                  </a>
                  {ShowWebsite && AppContext.MNVMoverID == 0 && (
                    <div className="left-sub-items">
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/blogarticle"
                      >
                        <span>Blog Article</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/location"
                      >
                        <span>Locations</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/pages"
                      >
                        <span>Pages</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/userReview"
                      >
                        <span>User Review</span>
                      </a>
                    </div>
                  )}
                </div>

                <div className="left-menu-items">
                  <a
                    className="flex-da click-link flex-jsb"
                    onClick={ShowHideConfiguration}
                  >
                    <span>Configuration</span>
                    <img
                      className={`drop-icon ${
                        ShowConfiguration ? "rotate-open" : ""
                      }`}
                      src="images/chev-down.svg"
                      width={10}
                      height={10}
                    />
                  </a>
                  {ShowConfiguration && AppContext.MNVMoverID == 0 && (
                    <div className="left-sub-items">
                      {AppContext.IsSemiAdmin == false && (
                        <>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/user"
                          >
                            <span>User</span>
                          </a>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/rates"
                          >
                            <span>Rates</span>
                          </a>
                        </>
                      )}
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/mover"
                      >
                        <span>Mover</span>
                      </a>
                      {AppContext.IsSemiAdmin == false && (
                        <>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/moverrates"
                          >
                            <span>Mover Rates</span>
                          </a>
                        </>
                      )}
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/moverunavailability"
                      >
                        <span>Mover Unavailability</span>
                      </a>
                      {AppContext.IsSemiAdmin == false && (
                        <>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/movertypes"
                          >
                            <span>Mover Types</span>
                          </a>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/vantypes"
                          >
                            <span>Van Types</span>
                          </a>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/van"
                          >
                            <span>Vans</span>
                          </a>
                        </>
                      )}
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/discount"
                      >
                        <span>Discount</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/campaign"
                      >
                        <span>Campaign</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/campaigndata"
                      >
                        <span>Campaign Data</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/holiday"
                      >
                        <span>Holiday</span>
                      </a>
                      {AppContext.IsSemiAdmin == false && (
                        <>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/timeslot"
                          >
                            <span>Time Slot</span>
                          </a>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/slots"
                          >
                            <span>Slots Management</span>
                          </a>
                        </>
                      )}
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/van_availability"
                      >
                        <span>Van Availability</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/largevans"
                      >
                        <span>Use Luton as Transit</span>
                      </a>
                      {AppContext.IsSemiAdmin == false && (
                        <>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/systemconfiguration"
                          >
                            <span>System Configuration</span>
                          </a>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/emailtemplate"
                          >
                            <span>Email Templates</span>
                          </a>
                          <a
                            className="flex-da click-link flex-jsb"
                            href="https://app.finestvan.co.uk/generaldata"
                          >
                            <span>General Data</span>
                          </a>
                        </>
                      )}
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/minimumcharge"
                      >
                        <span>Minimum Charges</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/bankholiday"
                      >
                        <span>Bank Holidays</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/recentjob"
                      >
                        <span>Recent Jobs</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/locationstats"
                      >
                        <span>Location Stats</span>
                      </a>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/locationmove"
                      >
                        <span>Location Move</span>
                      </a>
                    </div>
                  )}
                </div>
              </>
            )}

            {AppContext.MNVMoverID == 0 && AppContext.IsBlogAdmin == true && (
              <>
                <div className="left-menu-items">
                  <a
                    className="flex-da click-link flex-jsb"
                    href="https://app.finestvan.co.uk/blogarticle"
                  >
                    <span>Blog Article</span>
                  </a>
                </div>
              </>
            )}

            {AppContext.MNVMoverID > 0 && (
              <>
                <div className="left-menu-items">
                  <a
                    className="flex-da click-link flex-jsb"
                    href="https://app.finestvan.co.uk/driverdashboard"
                  >
                    <span>Dashboard</span>
                  </a>
                  {AppContext.IsContractor == true && (
                    <>
                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/earningsdashboard"
                      >
                        <span>Earnings</span>
                      </a>

                      <a
                        className="flex-da click-link flex-jsb"
                        href="https://app.finestvan.co.uk/tippingdashboard"
                      >
                        <span>Tipping</span>
                      </a>
                    </>
                  )}
                  {AppContext.IsContractor == false && (
                    <>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "flex-da click-link active"
                            : "flex-da click-link"
                        }
                        to={"/employeeview"}
                      >
                        Employee View
                      </NavLink>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "flex-da click-link active"
                            : "flex-da click-link"
                        }
                        to={"/employeetimelog"}
                      >
                        Employee Time Logs
                      </NavLink>
                    </>
                  )}
                  <a
                    className="flex-da click-link flex-jsb"
                    href="https://app.finestvan.co.uk/myconversations"
                  >
                    <span>Messages</span>
                  </a>
                  <a
                    className="flex-da click-link flex-jsb"
                    href="https://app.finestvan.co.uk/moverunavailability"
                  >
                    <span>Mover Unavailability</span>
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
